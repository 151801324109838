<template>
  <!-- skelton -->
  <div class="container" v-if="loading">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-12 mt-2">
        <Skeleton width="20%" class="mb-2"></Skeleton>
        <Skeleton height="2rem" width="40%" class="mb-3"></Skeleton>
        <Skeleton width="25%" class="mt-3"></Skeleton>
        <Skeleton class="my-3" size="10rem"></Skeleton>
        <Skeleton width="25%" class="my-3"></Skeleton>
      </div>
      <div class="col-lg-6 col-md-6 col-12 mt-2">
        <Skeleton width="20%" class="mb-2"></Skeleton>
        <Skeleton height="2rem" width="40%" class="mb-3"></Skeleton>
        <Skeleton width="25%" class="mt-3"></Skeleton>
        <Skeleton class="my-3" size="10rem"></Skeleton>
        <Skeleton width="25%" class="my-3"></Skeleton>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-4 col-12 my-4" v-for="i in 16" :key="i">
        <Skeleton width="20%" class="mb-2"></Skeleton>
        <Skeleton height="2rem" class="mb-3"></Skeleton>
      </div>
    </div>
  </div>
  <!-- skelton -->
  <form class="card_style" @submit.prevent="addSetting()" v-else>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="d-flex gap-3 align-items-center mb-3 px-3">
            <AddFavDailog v-tooltip.left="$t('add_to_faviorites')" />
            <h4 class="title">{{ $t("website/setting") }}</h4>
          </div>
        </div>
        <div class="col-6">
          <div class="d-flex align-items-start justify-content-between">
            <div>
              <label for="imageEntry" class="w-100">{{
                $t("upload image")
              }}</label>
              <input
                type="file"
                name=""
                id="imageEntry"
                class="form-control w-100"
                @change="onFileChange"
                accept="image/*"
              />

              <div class="my-1">
                <p class="fs-6 fw-lighter mb-0">{{ $t("default image") }}</p>
                <div class="image_upload">
                  <div
                    for="imageEntry"
                    class="upload_img"
                    v-if="imageEntry == ''"
                  >
                    <img
                      :src="Form.logo ? Form.logo : defaultImage"
                      alt="user"
                    />
                  </div>
                  <img
                    :src="imageEntry"
                    v-else-if="imageEntry"
                    class="entry_image"
                    alt="entry"
                  />
                </div>
                <p
                  v-tooltip="
                    Form.default_image ? Form.default_image : defaultImage
                  "
                >
                  {{
                    maxWordTruncate(Form.logo ? Form.logo : defaultImage, 20)
                  }}
                </p>
              </div>
            </div>
          </div>
          <!--          <span-->
          <!--            class="text-danger"-->
          <!--            v-if="v$.imageEntry.$error"-->
          <!--            v-text="v$.imageEntry.$errors[0].$message"-->
          <!--          ></span>-->
        </div>
        <div class="col-6">
          <div class="d-flex align-items-start justify-content-between">
            <div>
              <label for="logoEntry" class="w-100">{{
                $t("upload logo")
              }}</label>
              <input
                type="file"
                name=""
                id="logoEntry"
                class="form-control w-100"
                @change="onLogoChange"
                accept="image/*"
              />

              <div class="my-1">
                <p class="fs-6 fw-lighter mb-0">{{ $t("default logo") }}</p>
                <div class="image_upload">
                  <div class="upload_img" v-if="logoEntry == ''">
                    <img
                      :src="
                        Form.default_image ? Form.default_image : defaultImage
                      "
                      alt="user"
                    />
                  </div>
                  <img
                    :src="logoEntry"
                    v-else-if="logoEntry"
                    class="entry_image"
                    alt="entry"
                  />
                </div>
                <p
                  v-tooltip="
                    Form.default_image ? Form.default_image : defaultImage
                  "
                >
                  {{
                    maxWordTruncate(
                      Form.default_image ? Form.default_image : defaultImage,
                      20
                    )
                  }}
                </p>
              </div>
            </div>
          </div>
          <!--          <span-->
          <!--            class="text-danger"-->
          <!--            v-if="v$.logoEntry.$error"-->
          <!--            v-text="v$.logoEntry.$errors[0].$message"-->
          <!--          ></span>-->
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="form-group">
            <label for="email">{{ $t("email") }}</label>
            <input
              type="email"
              id="email"
              class="form-control"
              v-model="Form.email"
              :placeholder="$t('email')"
            />
            <span
              class="text-danger"
              v-if="v$.Form.email.$error"
              v-text="v$.Form.email.$errors[0].$message"
            ></span>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="form-group">
            <label for="phone">{{ $t("phone") }}</label>
            <input
              type="text"
              id="phone"
              class="form-control plaintext"
              v-model="Form.phone"
              :placeholder="$t('phone')"
            />
            <span
              class="text-danger"
              v-if="v$.Form.phone.$error"
              v-text="v$.Form.phone.$errors[0].$message"
            ></span>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="form-group">
            <label for="address">{{ $t("address") }}</label>
            <input
              type="text"
              id="address"
              class="form-control"
              v-model="Form.address"
              :placeholder="$t('address')"
            />
            <span
              class="text-danger"
              v-if="v$.Form.address.$error"
              v-text="v$.Form.address.$errors[0].$message"
            ></span>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="form-group">
            <label for="commercial_registration_number">{{ $t("commercial_registration_number") }}</label>
            <input
              type="text"
              id="commercial_registration_number"
              class="form-control plaintext"
              v-model="Form.commercial_registration_number"
              :placeholder="$t('commercial_registration_number')"
            />
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="form-group">
            <label for="license_number">{{ $t("license_number") }}</label>
            <input
              type="text"
              id="license_number"
              class="form-control"
              v-model="Form.license_number"
              :placeholder="$t('license_number')"
            />
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="form-group">
            <label for="tax_number">{{ $t("tax_number") }}</label>
            <input
              type="text"
              id="tax_number"
              class="form-control"
              v-model="Form.tax_number"
              :placeholder="$t('tax_number')"
            />
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="form-group">
            <label for="whatsapp">whatsapp</label>
            <input
              type="text"
              id="whatsapp"
              class="form-control plaintext"
              v-model="Form.whatsapp"
              placeholder="whatsapp"
            />
            <!-- <span
              class="text-danger"
              v-if="v$.Form.whatsapp.$error"
              v-text="v$.Form.whatsapp.$errors[0].$message"
            ></span> -->
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="form-group">
            <label for="facebook">facebook</label>
            <input
              type="text"
              id="facebook"
              class="form-control"
              v-model="Form.facebook"
              placeholder="facebook"
            />
            <!-- <span
              class="text-danger"
              v-if="v$.Form.facebook.$error"
              v-text="v$.Form.facebook.$errors[0].$message"
            ></span> -->
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="form-group">
            <label for="twitter">twitter</label>
            <input
              type="text"
              id="twitter"
              class="form-control"
              v-model="Form.twitter"
              placeholder="twitter"
            />
            <!-- <span
              class="text-danger"
              v-if="v$.Form.twitter.$error"
              v-text="v$.Form.twitter.$errors[0].$message"
            ></span> -->
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="form-group">
            <label for="linkedin">linkedin</label>
            <input
              type="text"
              id="linkedin"
              class="form-control"
              v-model="Form.linkedin"
              placeholder="linkedin"
            />
            <!-- <span
              class="text-danger"
              v-if="v$.Form.linkedin.$error"
              v-text="v$.Form.linkedin.$errors[0].$message"
            ></span> -->
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="form-group">
            <label for="instagram">instagram</label>
            <input
              type="text"
              id="instagram"
              class="form-control"
              v-model="Form.instgram"
              placeholder="instagram"
            />
            <!-- <span
              class="text-danger"
              v-if="v$.Form.instgram.$error"
              v-text="v$.Form.instgram.$errors[0].$message"
            ></span> -->
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="form-group">
            <label for="tiktok">tiktok</label>
            <input
              type="text"
              id="tiktok"
              class="form-control"
              v-model="Form.tiktok"
              placeholder="tiktok"
            />
            <!-- <span
              class="text-danger"
              v-if="v$.Form.tiktok.$error"
              v-text="v$.Form.tiktok.$errors[0].$message"
            ></span> -->
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="form-group">
            <label for="youtube">youtube</label>
            <input
              type="text"
              id="youtube"
              class="form-control"
              v-model="Form.youtube"
              placeholder="youtube"
            />
            <!-- <span
              class="text-danger"
              v-if="v$.Form.youtube.$error"
              v-text="v$.Form.youtube.$errors[0].$message"
            ></span> -->
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="form-group">
            <label for="snapchat">snapchat</label>
            <input
              type="text"
              id="snapchat"
              class="form-control"
              v-model="Form.snapchat"
              placeholder="snapchat"
            />
            <!-- <span
              class="text-danger"
              v-if="v$.Form.snapchat.$error"
              v-text="v$.Form.snapchat.$errors[0].$message"
            ></span> -->
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="form-group">
            <label for="title">Title</label>
            <input
              type="text"
              id="title"
              class="form-control"
              v-model="Form.title"
              placeholder="Title"
            />
            <span
              class="text-danger"
              v-if="v$.Form.title.$error"
              v-text="v$.Form.title.$errors[0].$message"
            ></span>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="form-group">
            <label for="Meta">Meta Title</label>
            <input
              type="text"
              id="Meta"
              class="form-control"
              v-model="Form.meta_title"
              placeholder="Meta Title"
            />
            <span
              class="text-danger"
              v-if="v$.Form.meta_title.$error"
              v-text="v$.Form.meta_title.$errors[0].$message"
            ></span>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="form-group">
            <label for="Description">Meta Description</label>
            <input
              type="text"
              id="Description"
              class="form-control"
              v-model="Form.mete_description"
              placeholder="Meta Description"
            />
            <span
              class="text-danger"
              v-if="v$.Form.mete_description.$error"
              v-text="v$.Form.mete_description.$errors[0].$message"
            ></span>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="form-group">
            <label for="Primary">Primary Color</label>
            <input
              type="color"
              id="Primary"
              class="form-control"
              v-model="Form.primary_color"
              placeholder="Primary Color"
            />
            <span
              class="text-danger"
              v-if="v$.Form.primary_color.$error"
              v-text="v$.Form.primary_color.$errors[0].$message"
            ></span>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="form-group">
            <label for="Secondary">Secondary Color</label>
            <input
              type="color"
              id="Secondary"
              class="form-control"
              v-model="Form.secondary_color"
              placeholder="Secondary Color"
            />
            <span
              class="text-danger"
              v-if="v$.Form.secondary_color.$error"
              v-text="v$.Form.secondary_color.$errors[0].$message"
            ></span>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="form-group">
            <label for="Header">Header Search Color</label>
            <input
              type="color"
              id="Header"
              class="form-control"
              v-model="Form.header_color"
              placeholder="Header Search Color"
            />
            <span
              class="text-danger"
              v-if="v$.Form.header_color.$error"
              v-text="v$.Form.header_color.$errors[0].$message"
            ></span>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="form-group">
            <label for="Navbar">Navbar Color</label>
            <input
              type="color"
              id="Navbar"
              class="form-control"
              v-model="Form.navbar_color"
              placeholder="Navbar Color"
            />
            <span
              class="text-danger"
              v-if="v$.Form.navbar_color.$error"
              v-text="v$.Form.navbar_color.$errors[0].$message"
            ></span>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="form-group">
            <label for="Footer">Footer Color</label>
            <input
              type="color"
              id="Footer"
              class="form-control"
              v-model="Form.footer_color"
              placeholder="Footer Color"
            />
            <span
              class="text-danger"
              v-if="v$.Form.footer_color.$error"
              v-text="v$.Form.footer_color.$errors[0].$message"
            ></span>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="form-group">
            <label for="Mobile">Mobile Primary</label>
            <input
              type="color"
              id="Mobile"
              class="form-control"
              v-model="Form.mobile_primary"
              placeholder="Mobile Primary"
            />
            <span
              class="text-danger"
              v-if="v$.Form.mobile_primary.$error"
              v-text="v$.Form.mobile_primary.$errors[0].$message"
            ></span>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="form-group">
            <label for="Mobile_Secondary">Mobile Secondary</label>
            <input
              type="color"
              id="Mobile_Secondary"
              class="form-control"
              v-model="Form.mobile_secondary"
              placeholder="Mobile Secondary"
            />
            <span
              class="text-danger"
              v-if="v$.Form.mobile_secondary.$error"
              v-text="v$.Form.mobile_secondary.$errors[0].$message"
            ></span>
          </div>
        </div>
        <div class="col-12">
          <button v-if="!loading" type="submit" class="btn PrimaryButton">
            {{ $t("save") }}
          </button>
          <button v-else type="submit" class="btn PrimaryButton">
            {{ $t("save") }}
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import Skeleton from "primevue/skeleton";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
export default {
  name: "setting-website",
  data() {
    return {
      v$: useVuelidate(),
      Form: {
        primary_color: "#000",
        secondary_color: "#000",
        header_color: "#000",
        navbar_color: "#000",
        footer_color: "#000",
        mobile_primary: "#000",
        mobile_secondary: "#000",
        email: "",
        phone: "",
        address: "",
        whatsapp: "",
        facebook: "",
        twitter: "",
        linkedin: "",
        instgram: "",
        tiktok: "",
        youtube: "",
        snapchat: "",
        title: "",
        meta_title: "",
        mete_description: "",
        default_image: "",
        commercial_registration_number: "",
        license_number: "",
        tax_number: "",
      },
      imageEntry: "",
      logoEntry: "",
      loading: true,
      defaultImage: require("@/assets/media/image/user.png"),
    };
  },
  validations() {
    return {
      Form: {
        address: {
          required,
        },
        phone: {
          required,
        },
        email: {
          required,
          email,
        },
        // whatsapp: {
        //   required,
        // },
        // facebook: {
        //   required,
        // },
        // twitter: {
        //   required,
        // },
        // linkedin: {
        //   required,
        // },
        // instgram: {
        //   required,
        // },
        // tiktok: {
        //   required,
        // },
        // youtube: {
        //   required,
        // },
        // snapchat: {
        //   required,
        // },
        title: {
          required,
        },
        meta_title: {
          required,
        },
        mete_description: {
          required,
        },
        primary_color: {
          required,
        },
        secondary_color: {
          required,
        },
        header_color: {
          required,
        },
        navbar_color: {
          required,
        },
        footer_color: {
          required,
        },
        mobile_primary: {
          required,
        },
        mobile_secondary: {
          required,
        },
      },
      // imageEntry: {
      //   required,
      // },
      // logoEntry: {
      //   required,
      // },
    };
  },
  methods: {
    onFileChange(event) {
      this.fileData = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = () => (this.imageEntry = reader.result);
    },
    onLogoChange(event) {
      this.logoData = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.logoData);
      reader.onload = () => (this.logoEntry = reader.result);
    },
    addSetting() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const formData = new FormData();

        if (this.fileData instanceof File) {
          formData.append("logo", this.fileData);
        }
        if (this.logoData instanceof File) {
          formData.append("default_image", this.logoData);
        }

        formData.append("title", this.Form.title);
        formData.append("meta_title", this.Form.meta_title);
        formData.append("mete_description", this.Form.mete_description);
        formData.append("email", this.Form.email);
        formData.append("primary_color", this.Form.primary_color);
        formData.append("secondary_color", this.Form.secondary_color);
        formData.append("header_color", this.Form.header_color);
        formData.append("navbar_color", this.Form.navbar_color);
        formData.append("footer_color", this.Form.footer_color);
        formData.append("mobile_primary_color", this.Form.mobile_primary);
        formData.append("mobile_secondary_color", this.Form.mobile_secondary);
        formData.append(
          "commercial_registration_number",
          this.Form.commercial_registration_number
        );
        formData.append("license_number", this.Form.license_number);
        formData.append("tax_number", this.Form.tax_number);
        formData.append("phone", this.Form.phone);
        formData.append("address", this.Form.address);
        formData.append("whatsapp", this.Form.whatsapp);
        formData.append("facebook", this.Form.facebook);
        formData.append("twitter", this.Form.twitter);
        formData.append("linkedin", this.Form.linkedin);
        formData.append("instgram", this.Form.instgram);
        formData.append("tiktok", this.Form.tiktok);
        formData.append("youtube", this.Form.youtube);
        formData.append("snapchat", this.Form.snapchat);

        axios
          .post("/website/organization_settings_create_or_update", formData)
          .then(({ data }) => {
            Swal.fire({
              title: "",
              text: this.$tc(data.message),
              icon: "success",
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
          });
      }
    },
    showSetting() {
      axios
        .get(`/fetch_organization_setting`)
        .then(({ data }) => {
          // console.log(data.data);
          this.Form.title = data.data.title != "null" ? data.data.title : "";
          this.Form.meta_title =
            data.data.meta_title != "null" ? data.data.meta_title : "";
          this.Form.mete_description =
            data.data.mete_description != "null"
              ? data.data.mete_description
              : "";
          this.fileData = data.data.logo;
          this.Form.logo = data.data.logo != "null" ? data.data.logo : "";
          this.Form.default_image =
            data.data.default_image != "null" ? data.data.default_image : "";
          this.Form.primary_color =
            data.data.primary_color != "null" ? data.data.primary_color : "";
          this.Form.secondary_color =
            data.data.secondary_color != "null"
              ? data.data.secondary_color
              : "";
          this.Form.header_color =
            data.data.header_color != "null" ? data.data.header_color : "";
          this.Form.navbar_color =
            data.data.navbar_color != "null" ? data.data.navbar_color : "";
          this.Form.footer_color =
            data.data.footer_color != "null" ? data.data.footer_color : "";

          this.Form.mobile_primary =
            data.data.mobile_primary_color != "null"
              ? data.data.mobile_primary_color
              : "";
          this.Form.mobile_secondary =
            data.data.mobile_secondary_color != "null"
              ? data.data.mobile_secondary_color
              : "";

          this.Form.email = data.data.email != "null" ? data.data.email : "";
          this.Form.phone = data.data.phone != "null" ? data.data.phone : "";
          this.Form.commercial_registration_number =
            data.data.commercial_registration_number != "null"
              ? data.data.commercial_registration_number
              : "";
          this.Form.license_number =
            data.data.license_number != "null" ? data.data.license_number : "";
          this.Form.tax_number =
            data.data.tax_number != "null" ? data.data.tax_number : "";
          this.Form.address =
            data.data.address != "null" ? data.data.address : "";
          this.Form.whatsapp =
            data.data.whatsapp != "null" ? data.data.whatsapp : "";
          this.Form.facebook =
            data.data.facebook != "null" ? data.data.facebook : "";
          this.Form.twitter =
            data.data.twitter != "null" ? data.data.twitter : "";
          this.Form.linkedin =
            data.data.linkedin != "null" ? data.data.linkedin : "";
          this.Form.instgram =
            data.data.instgram != "null" ? data.data.instgram : "";
          this.Form.tiktok = data.data.tiktok != "null" ? data.data.tiktok : "";
          this.Form.youtube =
            data.data.youtube != "null" ? data.data.youtube : "";
          this.Form.snapchat =
            data.data.snapchat != "null" ? data.data.snapchat : "";
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.Form = {};
          return error;
        })
        .finally(() => {
          this.loading = false;
          //Perform action in always
        });
    },
  },
  created() {
    this.showSetting();
  },
  components: {
    Skeleton,
    AddFavDailog,
  },
};
</script>

<style lang="scss" scoped>
label {
  margin-bottom: 0.25rem;
  font-size: 0.8rem !important;
}
.form-control {
  padding: 0.5rem !important;
  font-size: 0.75rem !important;
}
.PrimaryButton {
  display: block;
  width: fit-content;
  margin-inline-start: auto;
  margin-top: 0.5rem;
}
.form-group {
  margin-bottom: 1rem;
}
.image_upload {
  width: 90px;
  height: 90px;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .upload_img,
  .entry_image {
    max-width: 100%;
    img {
      max-width: 100%;
      height: initial;
    }
  }
  .upload_img {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.form-group {
  label {
    margin-bottom: 0.5rem;
  }
}
.main_header_style {
  margin-bottom: 0;
}
</style>
